import { createLibp2p, Libp2p } from "libp2p";
import { identify } from "@libp2p/identify";
import { noise } from "@chainsafe/libp2p-noise";
import { yamux } from "@chainsafe/libp2p-yamux";
import { bootstrap } from "@libp2p/bootstrap";
import { kadDHT } from "@libp2p/kad-dht";
import { multiaddr, Multiaddr } from "@multiformats/multiaddr";
import { sha256 } from "multiformats/hashes/sha2";
import type {
  Message,
  SignedMessage,
  UnsignedMessage,
} from "@libp2p/interface-pubsub";
import { gossipsub } from "@chainsafe/libp2p-gossipsub";
import { webSockets } from "@libp2p/websockets";
import { webTransport } from "@libp2p/webtransport";
import { webRTC, webRTCDirect } from "@libp2p/webrtc";

import { pubsubPeerDiscovery } from "@libp2p/pubsub-peer-discovery";
import {
  CHAT_FILE_TOPIC,
  CHAT_TOPIC,
  PEER_DISCOVERY_TOPIC,
  WEBRTC_BOOTSTRAP_NODE,
  WEBRTC_BOOTSTRAP_NODE2,
  WEBRTC_BOOTSTRAP_NODE3,
} from "./constants";
import * as filters from "@libp2p/websockets/filters";
import { circuitRelayTransport } from "@libp2p/circuit-relay-v2";
import devLog from "../Utils/Logger";

export async function startLibp2p() {
  const libp2p: any = await createLibp2p({
    addresses: {
      listen: ["/webrtc"],
    },
    transports: [
      webTransport(),
      webSockets({
        filter: filters.all,
      }),
      webRTC({
        rtcConfiguration: {
          iceServers: [
            {
              urls: [
                // "stun:stun.l.google.com:19302",
                // "stun:global.stun.twilio.com:3478",
                "turn:mychatpwa.hopto.org:3478",
              ],
              username: "test",
              credential: "test123",
            },
          ],
        },
      }),
      webRTCDirect(),
      circuitRelayTransport({
        discoverRelays: 1,
      }),
    ],
    connectionManager: {
      maxConnections: 10,
      minConnections: 5,
    },
    connectionEncryption: [noise()],
    streamMuxers: [yamux()],
    connectionGater: {
      denyDialMultiaddr: async () => false,
    },
    peerDiscovery: [
      bootstrap({
        list: [
          WEBRTC_BOOTSTRAP_NODE,
          WEBRTC_BOOTSTRAP_NODE2,
          WEBRTC_BOOTSTRAP_NODE3,
        ],
      }),
      pubsubPeerDiscovery({
        interval: 1000,
      }),
    ],
    services: {
      pubsub: gossipsub({
        allowPublishToZeroPeers: true,
        msgIdFn: msgIdFnStrictNoSign,
        ignoreDuplicatePublishError: true,
        emitSelf: true,
      }),
      dht: kadDHT({
        logPrefix: "/mychatpwa",
        maxInboundStreams: 5000,
        maxOutboundStreams: 5000,
        clientMode: true,
      }),
      identify: identify(),
    },
  });

  libp2p.services.pubsub.subscribe(CHAT_TOPIC);
  libp2p.services.pubsub.subscribe(CHAT_FILE_TOPIC);
  libp2p.services.pubsub.subscribe(PEER_DISCOVERY_TOPIC);

  // @ts-ignore
  libp2p.addEventListener("self:peer:update", ({ detail: { peer } }) => {
    // @ts-ignore
    const multiaddrs = peer.addresses.map(({ multiaddr }) => multiaddr);

    devLog(
      `changed multiaddrs: peer ${peer.id.toString()} multiaddrs: ${multiaddrs}`,
    );
  });
  // @ts-ignore
  libp2p.addEventListener("peer:discovery", (evt) => {
    devLog(
      `GK discovery peer ${Object.keys(evt.detail)}  rest: ${
        evt.detail.multiaddrs
      } id? : ${evt.detail.id}`,
    );
  });
  // @ts-ignore
  libp2p.addEventListener("peer:identify", (evt) => {
    devLog(`GK identify peer ${evt.detail}`);
  });

  interface theEventMessage {
    detail: SignedMessage | UnsignedMessage;
  }
  libp2p.services.pubsub.addEventListener(
    "message",
    (message: theEventMessage) => {
      if (message.detail.topic !== PEER_DISCOVERY_TOPIC) {
        return;
      }
      devLog("GK dial Received message:", message);
      if (message.detail.type === "signed") {
        devLog(
          "GK dial signed #1 type, topic ? ",
          message.detail.type,
          message.detail.topic,
        );
      }

      if (message.detail.type != "signed") {
        devLog("GK dial signed #2 ? ", message.detail.type);
      }

      if (message.detail.data === undefined) {
        devLog("GK dial Received message with undefined data");
        return;
      }

      try {
        // Attempt to connect to the new peer
        // const newPeerMultiaddr = message.detail.data.toString();
        const newPeerMultiaddr = new TextDecoder().decode(message.detail.data);
        devLog(
          "GK dial Attempting to connect to the new peer:",
          newPeerMultiaddr,
        );

        devLog(
          "GK dial Successfully connected to the new peer, newPeerMultiaddr: ",
          newPeerMultiaddr,
        );
      } catch (e) {
        devLog("GK dial Error connecting to new peer:", e);
      }
    },
  );

  return libp2p;
}

// message IDs are used to dedupe inbound messages
// every agent in network should use the same message id function
// messages could be perceived as duplicate if this isnt added (as opposed to rust peer which has unique message ids)
export async function msgIdFnStrictNoSign(msg: Message): Promise<Uint8Array> {
  var enc = new TextEncoder();

  const signedMessage = msg as SignedMessage;
  const encodedSeqNum = enc.encode(signedMessage.sequenceNumber.toString());
  // const encodedSeqNum = enc.encode(signedMessage.data.toString());
  return await sha256.encode(encodedSeqNum);
}

export const connectToMultiaddr =
  (libp2p: Libp2p) => async (multiaddr: Multiaddr) => {
    devLog(`dialling: ${multiaddr.toString()}`);
    try {
      const conn = await libp2p.dial(multiaddr);
      devLog("connected to", conn.remotePeer, "on", conn.remoteAddr);
      return conn;
    } catch (e) {
      // console.error(e);
      throw e;
    }
  };

import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

const Modal = ({ headerText, mainInfoText, setShowModal }) => {
  useEffect(() => {
    // Disable background scroll
    document.body.style.overflow = "hidden";

    return () => {
      // Re-enable background scroll
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full px-4 py-10">
      <div className="relative mx-auto p-5 border w-full max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl bg-white shadow-lg rounded-md">
        <h3 className="text-lg md:text-xl font-bold text-center mb-4">
          {headerText}
        </h3>
        <div className="markdown-content text-sm md:text-base text-gray-600 overflow-auto h-full mb-4">
          <ReactMarkdown>{mainInfoText}</ReactMarkdown>
        </div>
        <div className="text-right">
          <button
            onClick={() => setShowModal(false)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

// import React, { useEffect } from "react";
// import ReactMarkdown from "react-markdown";
//
// const Modal = ({ headerText, mainInfoText, setShowModal }) => {
//   useEffect(() => {
//     // Disable background scroll
//     document.body.style.overflow = "hidden";
//     return () => {
//       // Re-enable background scroll
//       document.body.style.overflow = "";
//     };
//   }, []);
//
//   return (
//     <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full px-4 py-10">
//       <div className="relative mx-auto p-5 border w-full max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl bg-white shadow-lg rounded-md">
//         <h3 className="text-lg md:text-xl font-bold text-center mb-4">
//           {headerText}
//         </h3>
//         <div className="markdown-content text-sm md:text-base text-gray-600 overflow-auto max-h-96 mb-4 relative">
//           <ReactMarkdown>{mainInfoText}</ReactMarkdown>
//           <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
//         </div>
//         <div className="text-right">
//           <button
//             onClick={() => setShowModal(false)}
//             className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
//
// export default Modal;

// import React, { useEffect, useRef, useState } from "react";
// import ReactMarkdown from "react-markdown";
//
// const Modal = ({ headerText, mainInfoText, setShowModal }) => {
//   const [showScrollArrow, setShowScrollArrow] = useState(false);
//   const contentRef = useRef(null);
//
//   useEffect(() => {
//     // Check if the content is scrollable
//     const checkScrollable = () => {
//       const current = contentRef.current;
//       const isScrollable = current.scrollHeight > current.clientHeight;
//       setShowScrollArrow(isScrollable);
//     };
//
//     // Disable background scroll
//     document.body.style.overflow = "hidden";
//     checkScrollable();
//
//     return () => {
//       // Re-enable background scroll
//       document.body.style.overflow = "";
//     };
//   }, []);
//
//   return (
//     <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full px-4 py-10">
//       <div className="relative mx-auto p-5 border w-full max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl bg-white shadow-lg rounded-md">
//         <h3 className="text-lg md:text-xl font-bold text-center mb-4">
//           {headerText}
//         </h3>
//         <div
//           ref={contentRef}
//           className="markdown-content text-sm md:text-base text-gray-600 overflow-auto max-h-96 mb-4 relative"
//         >
//           <ReactMarkdown>{mainInfoText}</ReactMarkdown>
//           {showScrollArrow && (
//             <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="24"
//                 height="24"
//                 fill="currentColor"
//                 viewBox="0 0 24 24"
//               >
//                 <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
//               </svg>
//             </div>
//           )}
//         </div>
//         <div className="text-right">
//           <button
//             onClick={() => setShowModal(false)}
//             className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
//
// export default Modal;

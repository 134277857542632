import React from "react";
import "./App.css"; // Global styles can still be used
import { AppWrapper } from "./context/ctx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeComponent from "./Components/HomeComponent";
import "./styles/globals.css";
import ChatViewPage from "./Components/ChatViewPage";
import LoginComponent from "./Components/LoginComponent";

function App() {
  return (
    <Router>
      <AppWrapper>
        <Routes>
          <Route path="/" element={<LoginComponent />}></Route>
          {/* Add LoginComponent as a route */}
          <Route path="/home" element={<ChatViewPage />}></Route>
          <Route path="/connections" element={<HomeComponent />}></Route>
          <Route path="*" element={<LoginComponent />}></Route>
        </Routes>
      </AppWrapper>
    </Router>
  );
}

export default App;

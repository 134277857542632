export const CHAT_TOPIC = "mychatpwa";
export const CHAT_FILE_TOPIC = "mychatpwa-file";

// Add a constant for your peer discovery topic
export const PEER_DISCOVERY_TOPIC = "mychat_pwa_discovery";

export const FILE_EXCHANGE_PROTOCOL = "/mychatpwa/1";

export const CIRCUIT_RELAY_CODE = 290;
export const MYCHATPWA_API_URL = "https://mychatpwa.hopto.org:9000/peers";
export const WEBRTC_BOOTSTRAP_NODE =
  "/dns4/mychatpwa.hopto.org/tcp/35000/wss/p2p/12D3KooWCWAwxt3xkat9zzKmNWjUJeMdkg2jpUL769nDkU1VN9Sk/p2p-circuit/p2p/12D3KooWKomsLdVqB7me55Znubg9qSzPqJ4pPHvjQCgWZ9Qygnuw";
// "/dns4/mychatpwa.hopto.org/tcp/443/wss/p2p/12D3KooWRnM6Y1TQWihSmNTWiqRXH12wEdJJ4xCmJ3fzPSBEGnkk/p2p-circuit/p2p/12D3KooWD8nSLsfr8C9NfuoLs4aUvtE6ZeLa5ec725wQZEXXha85";
// "/dns4/mychatpwa.hopto.org/tcp/443/wss/p2p/12D3KooWDtbfBqKJPYGPnp8KKfG1uhYcYdeq51M2WSMEBVJDjgE4/p2p-circuit/p2p/12D3KooWHbgzcj5vdnMiZrtsQo4txtcser5zZtmas78h3ruueqdv";
// "/ip4/164.92.229.178/udp/9090/webrtc-direct/certhash/uEiCyG5zCRpky38iwqX6RCuNvaAumkTM0dexnyVXjMf6QLA/p2p/12D3KooWDsKuyhLWZwXXwFph1Tgd2CWs4RJFktsVKdQL94uHLsZv";
export const WEBRTC_BOOTSTRAP_NODE2 =
  "/dns4/mychatpwa.hopto.org/tcp/35000/wss/p2p/12D3KooWMq6sPJs7QgLQDA4q9mgo5hCuULY16ferA5dfx7LTYrNp/p2p-circuit/p2p/12D3KooWAfQ5HXpbmBgnooD41aM9e5Ezapx2pfJmZX3vATRHfbdG";
export const WEBRTC_BOOTSTRAP_NODE3 =
  "/dns4/mychatpwa.hopto.org/tcp/35000/wss/p2p/12D3KooWMq6sPJs7QgLQDA4q9mgo5hCuULY16ferA5dfx7LTYrNp/p2p-circuit/p2p/12D3KooWQGMMgEo1FbMw8NfJgQsZfcvo6ZRpE6npFP4WiW88ozDT";
export const RELAY_NODE =
  "/dns4/mychatpwa.hopto.org/tcp/443/wss/p2p/12D3KooWQ5uFJnjSKKmyYTmauasMjabkGifMcwunhJsyBNVq5QrH";
export const WEBTRANSPORT_BOOTSTRAP_NODE =
  "/ip4/164.92.229.178/udp/9095/quic-v1/webtransport/certhash/uEiDZIdF6aYjPFG_hNYUdJvnsXrlrb6SYbfih3YF239Eujw/certhash/uEiD9iIyO-ZuQeit4oxL5WwppcToPHM9nFmyZOYjRf2Z6ww/p2p/12D3KooWPi5w2xUemmikdhjGH6fvXVM7ZRb59rwRQmUyesJK3wYy";
export const MAX_CONNECTIONS_LIMIT = 45;
export const INDEXED_DB_NAME = "MyChatPWADatabase";
export const INDEXED_DB_STORE_NAME = "MyChatPWAusers";
export const INDEXED_DB_KEY_NAME = "loginName";
export const EXPLANATION_INFO_1 = `You are about to provide your login name. There is no profile nor account data.
This is a p2p application in early stage. The name you enter is only for display purposes.
Once entered will be stored on your device for subsequent run of the application.`;
export const HEADER_INFO_1 = "Application info";
export const HEADER_INFO_2 = "Chat Connection Info";
export const HEADER_INFO_3 = "Multiaddr & Connection peer info";
export const EXPLANATION_INFO_2 = `
**Chat Connection Info**
This chat application operates on a decentralized, peer-to-peer network, leveraging a publish-subscribe model for communication. To facilitate message exchange, your device establishes direct connections with peers subscribed to the same topic.

**Please Note:**
- **Currently Available Users:** The functionality to view a list of active users is still in development and not yet operational.
- **Chat History:** Currently, the app does not store chat history. We plan to introduce this feature in upcoming updates.

**Key Points:**
- **Messaging Without Immediate Connection:** You may occasionally be able to compose and send messages even when a peer connection is not visibly active. Rest assured, these messages will be transmitted as soon as a connection is re-established.
  - *Tip:* Keep typing your message and repeating the attempt for a reasonable duration or number of tries; other peers are likely to respond.
- **Connection Loss on Background or Screen Off:** When using the PWA (Progressive Web App), be aware that putting the app in the background or turning off your device's screen may lead to a loss of connection. In such cases, you are likely required to close and restart the application to re-establish the connection.
- **Reconnecting for Updates:** Encountering issues or missing new messages? A simple solution is to close and then reopen the app. This process refreshes peer connections and ensures your chat is up-to-date.
  - *For PWA Users:* If issues persist, consider 'reinstalling' the app by removing it from your home screen, then accessing it through a web browser and re-adding it to your home screen.

- **Network Reliance:** The efficiency of the app hinges on the number of peers present and their availability within the network.
  - *User Experience Disclaimer:* Please note that your experience may vary depending on network conditions and the availability of peers. We appreciate your support for our decentralized chat app!
`;

export const EXPLANATION_INFO_3 = `**Connection Management**
Welcome to the Connection page! Here, you can manage direct connections with other nodes in the network.
**Features:**
- **Your Multiaddress:** At the top, you'll find your unique multiaddress. This address allows others to connect directly to your node.
- **Connect to a Peer:** Use the input field to paste another user's multiaddress. Then, hit the 'Connect' button to establish a peer-to-peer connection.
- **View Connected Nodes:** Below, there's a list of nodes you're currently connected to. This helps you keep track of your active peer connections.
Remember, direct connections enhance the network's robustness and your chat experience. Use this page to expand your network!
`;

import { useLibp2pContext } from "../context/ctx";
import { LoginNameContext } from "../context/loginname-ctx";
import { useFetchPeers } from "../context/fetchpeers-ctx";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Message } from "@libp2p/interface-pubsub";
import {
  CHAT_FILE_TOPIC,
  CHAT_TOPIC,
  FILE_EXCHANGE_PROTOCOL,
  PEER_DISCOVERY_TOPIC,
} from "../lib/constants";

import { ChatMessage, useChatContext } from "../context/chat-ctx";
import { v4 as uuidv4 } from "uuid";
import { ChatFile, useFileChatContext } from "../context/file-ctx";
import { pipe } from "it-pipe";
import map from "it-map";
import { fromString as uint8ArrayFromString } from "uint8arrays/from-string";
import { toString as uint8ArrayToString } from "uint8arrays/to-string";
import * as lp from "it-length-prefixed";
import Blockies from "react-blockies";

import { PeerId } from "@libp2p/interface";
import { usePeerContext } from "../context/peer-ctx";
import { useListenAddressesContext } from "../context/listen-addresses-ctx";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import devLog from "../Utils/Logger";

interface MessageProps extends ChatMessage {}

function MessageComponent({ msg, fileObjectUrl, from, peerId }: MessageProps) {
  const msgref = useRef(null);
  const { libp2p } = useLibp2pContext();

  function splitText(msg: string) {
    const parts = msg?.split("who:");
    if (parts.length === 2) {
      const partOne = parts[0].trim(); // text before 'who:'
      const partTwo = parts[1].trim(); // text after 'who:'

      return { partOne, partTwo };
    } else {
      // Handle cases where 'who:' is not found or found more than once
      return { error: "'who:' not found or found multiple times" };
    }
  }
  const { partOne, partTwo } = splitText(msg);
  return (
    <li
      ref={msgref}
      className={`flex ${from === "me" ? "justify-end" : "justify-start"}`}
    >
      <div className="flex relative max-w-full px-4 py-2 text-gray-700 rounded shadow bg-white">
        <div className="flex items-center mr-2">
          <Blockies
            seed={peerId}
            size={15}
            scale={3}
            className="rounded max-h-10 max-w-10"
          />
        </div>
        <div className="block w-full overflow-hidden">
          <p className="whitespace-normal break-words">
            {partOne ? partOne : msg}
          </p>
          <p>
            {fileObjectUrl ? (
              <a href={fileObjectUrl} target="_blank" rel="noopener noreferrer">
                <b>Download</b>
              </a>
            ) : (
              ""
            )}
          </p>
          <p className="italic text-gray-400">
            {peerId !== libp2p.peerId.toString()
              ? `from: ${partTwo ? partTwo : ""} (${peerId.slice(-4)})`
              : null}
          </p>
        </div>
      </div>
    </li>
  );
}

export default function ChatContainer() {
  const { libp2p } = useLibp2pContext();
  const { messageHistory, setMessageHistory } = useChatContext();
  const { files, setFiles } = useFileChatContext();
  const [input, setInput] = useState<string>("");
  const fileRef = useRef<HTMLInputElement>(null);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  const { peerStats, setPeerStats } = usePeerContext();
  const { listenAddresses, setListenAddresses } = useListenAddressesContext();

  const { fetchPeers, multiaddrs } = useFetchPeers();
  const [topicPeers, setTopicPeers] = useState(0);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // Adjust the height of the textarea as the content changes
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [input]);

  useEffect(() => {
    const _fetchPeers = async () => {
      try {
        await fetchPeers();
        devLog(
          `GK Chat UseEffect [fetchPeers] libp2p.services.pubsub.getSubscribers: ${libp2p.services.pubsub.getSubscribers.length}`,
        );
      } catch (e) {
        devLog(`GK <Chat view> error fetching peers from context: ${e}`);
      } finally {
        devLog(`GK Chat UseEffect finally clause entered`);
      }
    };

    _fetchPeers()
      .then(() => {
        devLog(`GK <Chat view> then peers fetching multiaddrs ${multiaddrs}`);
      })
      .catch((reason) => {
        devLog(`GK  <Chat view> fetch peers rejected promise: ${reason}`);
      });
  }, [fetchPeers]); // Add fetchPeers as a dependency if it's coming from props or context

  useEffect(() => {
    devLog(
      `GK <Chat view> multiaddrs from useFetchPeers multiaddrs.lenght: ${
        libp2p.getPeers().length
      } topic: ${libp2p.services.pubsub.getSubscribers(CHAT_TOPIC).length}`,
    );
    setTopicPeers(libp2p.services.pubsub.getSubscribers(CHAT_TOPIC).length);
  }, [libp2p]);
  //libp2p.getPeers().length

  useEffect(() => {
    devLog(`GK topicPeers: ${topicPeers} `);
  }, [topicPeers]);

  // Effect hook to subscribe to pubsub events and update the message state hook
  useEffect(() => {
    const messageCB = async (evt: CustomEvent<Message>) => {
      devLog("gossipsub console log", evt.detail);
      // FIXME: Why does 'from' not exist on type 'Message'?
      const { topic, data } = evt.detail;

      switch (topic) {
        case CHAT_TOPIC: {
          chatMessageCB(evt, topic, data);
          break;
        }
        case CHAT_FILE_TOPIC: {
          chatFileMessageCB(evt, topic, data);
          break;
        }
        case PEER_DISCOVERY_TOPIC: {
          devLog(
            `GK evt: EER_DISCOVERY_TOPIC ${evt} topic: [${topic}] data: <${data}> `,
          );
          const d = new TextDecoder().decode(data);
          devLog(`GK evt: decode data:  ${d}`);
          if (evt.detail.type === "signed") {
            devLog(`GK signed message from ... ${evt.detail.from}`);
          }

          break;
        }
        default: {
          // throw new Error(`Unexpected gossipsub topic: ${topic}`);
          devLog(`GK evt: ${evt} topic: [${topic}] data: <${data}> `);
          const d = new TextDecoder().decode(data);
          devLog(`GK evt: decode data:  ${d}`);
          if (evt.detail.type === "signed") {
            devLog(`GK signed message from ... ${evt.detail.from}`);
          }
        }
      }
    };

    const chatMessageCB = (
      evt: CustomEvent<Message>,
      topic: string,
      data: Uint8Array,
    ) => {
      const msg = new TextDecoder().decode(data);
      devLog(`GK chatMessageCB ${topic}: ${msg}`);

      // Append signed messages, otherwise discard
      if (evt.detail.type === "signed") {
        setMessageHistory([
          ...messageHistory,
          {
            msg,
            fileObjectUrl: undefined,
            from: "other",
            peerId: evt.detail.from.toString(),
          },
        ]);
        if (libp2p.peerId.toString() !== evt.detail.from.toString()) {
          setTopicPeers((prevValue) => prevValue + 1);
        }
      }
    };

    const chatFileMessageCB = async (
      evt: CustomEvent<Message>,
      topic: string,
      data: Uint8Array,
    ) => {
      const fileId = new TextDecoder().decode(data);

      // if the message isn't signed, discard it.
      if (evt.detail.type !== "signed") {
        return;
      }
      const senderPeerId = evt.detail.from as PeerId;

      const stream = await libp2p.dialProtocol(
        senderPeerId,
        FILE_EXCHANGE_PROTOCOL,
      );
      await pipe(
        [uint8ArrayFromString(fileId)],
        (source) => lp.encode(source),
        stream,
        (source) => lp.decode(source),
        async function (source) {
          for await (const data of source) {
            const body: Uint8Array = data.subarray();
            devLog(`request_response: response received: size:${body.length}`);

            const msg: ChatMessage = {
              msg: newChatFileMessage(fileId, body),
              fileObjectUrl: window.URL.createObjectURL(new Blob([body])),
              from: "other",
              peerId: senderPeerId.toString(),
            };
            setMessageHistory([...messageHistory, msg]);
          }
        },
      );
    };

    libp2p.services.pubsub.addEventListener("message", messageCB);

    libp2p.handle(FILE_EXCHANGE_PROTOCOL, ({ stream }) => {
      pipe(
        stream.source,
        (source) => lp.decode(source),
        (source) =>
          map(source, async (msg) => {
            const fileId = uint8ArrayToString(msg.subarray());
            const file = files.get(fileId)!;
            return file.body;
          }),
        (source) => lp.encode(source),
        stream.sink,
      );
    });

    return () => {
      (async () => {
        devLog(`GK cleanup useEffect #001`);
        // Cleanup handlers 👇
        // libp2p.services.pubsub.unsubscribe(CHAT_TOPIC)
        // libp2p.services.pubsub.unsubscribe(CHAT_FILE_TOPIC)
        libp2p.services.pubsub.removeEventListener("message", messageCB);
        await libp2p.unhandle(FILE_EXCHANGE_PROTOCOL);
      })();
    };
  }, [libp2p, messageHistory, setMessageHistory]);

  useEffect(() => {
    // Scroll to the bottom of the container when new messages are added
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messageHistory]);

  //**********************

  useEffect(() => {
    const interval = setInterval(() => {
      const connections = libp2p.getConnections();

      setPeerStats({
        ...peerStats,
        peerIds: connections.map((conn) => conn.remotePeer),
        connections: connections,
        connected: connections.length > 0,
      });

      connections.map((conn) =>
        devLog(
          `GK conn.remotepeer ${conn.remotePeer}  status: ${conn.status} remote addr: ${conn.remoteAddr}`,
        ),
      );
      devLog("GK connections: ", connections.length);
    }, 1000);

    listenAddresses.multiaddrs.map((ma, index) => {
      devLog("GK multiaddres: ", ma);
    });

    return () => {
      clearInterval(interval);
    };
  }, [libp2p, peerStats, setPeerStats]);

  useEffect(() => {
    const interval = setInterval(() => {
      const multiaddrs = libp2p.getMultiaddrs();

      setListenAddresses({
        ...listenAddresses,
        multiaddrs,
      });
      devLog(
        "GK multiaddrs: , listenAddresses: ",
        multiaddrs.length,
        listenAddresses,
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [libp2p, listenAddresses, setListenAddresses]);

  //**********************

  const sendMessage = useCallback(async () => {
    if (input === "") return;

    devLog(
      `peers in gossip for topic ${CHAT_TOPIC}:`,
      libp2p.services.pubsub.getSubscribers(CHAT_TOPIC).toString(),
    );

    const res = await libp2p.services.pubsub.publish(
      CHAT_TOPIC,
      new TextEncoder().encode(`${input} who:${loginName}`),
    );
    devLog(
      "sent message to: ",
      res.recipients.map((peerId) => peerId.toString()),
    );

    const myPeerId = libp2p.peerId.toString();
    devLog(`sendMessage input: ${input}`);
    setMessageHistory([
      ...messageHistory,
      { msg: input, fileObjectUrl: undefined, from: "me", peerId: myPeerId },
    ]);
    setInput("");
  }, [input, messageHistory, setInput, libp2p, setMessageHistory]);

  const sendFile = useCallback(
    async (readerEvent: ProgressEvent<FileReader>) => {
      const fileBody = readerEvent.target?.result as ArrayBuffer;

      const myPeerId = libp2p.peerId.toString();
      const file: ChatFile = {
        id: uuidv4(),
        body: new Uint8Array(fileBody),
        sender: myPeerId,
      };
      setFiles(files.set(file.id, file));

      devLog(
        `peers in gossip for topic ${CHAT_FILE_TOPIC}:`,
        libp2p.services.pubsub.getSubscribers(CHAT_FILE_TOPIC).toString(),
      );

      const res = await libp2p.services.pubsub.publish(
        CHAT_FILE_TOPIC,
        new TextEncoder().encode(file.id),
      );
      devLog(
        "sent file to: ",
        res.recipients.map((peerId) => peerId.toString()),
      );

      const msg: ChatMessage = {
        msg: newChatFileMessage(file.id, file.body),
        fileObjectUrl: window.URL.createObjectURL(new Blob([file.body])),
        from: "me",
        peerId: myPeerId,
      };
      setMessageHistory([...messageHistory, msg]);
    },
    [messageHistory, libp2p, setMessageHistory],
  );

  const newChatFileMessage = (id: string, body: Uint8Array) => {
    return `File: ${id} (${body.length} bytes)`;
  };

  const handleKeyUpTextArea = useCallback(
    async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key !== "Enter") {
        return;
      }
      sendMessage();
    },
    [sendMessage],
  );

  const handleSend = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      sendMessage();
    },
    [sendMessage],
  );

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInput(e.target.value);
    },
    [setInput],
  );

  const handleFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0]);
        reader.onload = (readerEvent) => {
          sendFile(readerEvent);
        };
      }
    },
    [sendFile],
  );

  const handleFileSend = useCallback(
    async (_e: React.MouseEvent<HTMLButtonElement>) => {
      fileRef?.current?.click();
    },
    [fileRef],
  );

  const loginContext = useContext(LoginNameContext);

  if (!loginContext) {
    // Handle the case when context is not available
    return <div>Error: LoginNameContext not found</div>;
  }

  const { loginName } = loginContext;
  let openConnectios = 0;
  const connectedPeers = libp2p.getPeers().map((peerId) => {
    openConnectios =
      openConnectios +
      libp2p
        .getConnections(peerId)
        .filter((connection) => connection.status === "open").length;
  });

  return (
    <div
      id="maincontainer"
      className="container mx-auto h-full flex flex-col border rounded"
    >
      <span className="block ml-2 font-bold text-gray-600">
        logged as: {loginName}
      </span>
      <div className="relative flex items-center p-3 border-b border-gray-300 space-x-4">
        <span className="text-xl">💁🏽‍♀️💁🏿‍♂️ </span>
        <span className="block ml-2 font-bold text-gray-600">Public Chat</span>
        <div className="flex">
          {topicPeers ? (
            <div className="flex">
              <div>connected</div>
              <CheckCircleIcon className="inline w-6 h-6 text-green-500" />
            </div>
          ) : (
            <div className="flex">
              <div>disconnected</div>
              <XCircleIcon className="w-6 h-6 text-red-500" />
            </div>
          )}
        </div>
      </div>
      <div
        id="chatspace"
        className="relative w-full flex-1 flex-col-reverse p-6 overflow-y-auto  bg-gray-100"
        ref={messageContainerRef}
      >
        <ul className="space-y-2">
          {/* messages start */}
          {messageHistory.map(({ msg, fileObjectUrl, from, peerId }, idx) => (
            <MessageComponent
              key={idx}
              msg={msg}
              fileObjectUrl={fileObjectUrl}
              from={from}
              peerId={peerId}
            />
          ))}
          {/* messages end */}
        </ul>
      </div>

      <div
        id="chatentrytext"
        className="flex items-center justify-between w-full p-3 border-t  border-gray-300"
      >
        <input
          ref={fileRef}
          className="hidden"
          type="file"
          onChange={handleFileInput}
        />
        <button onClick={handleFileSend}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
            />
          </svg>
        </button>

        <textarea
          ref={textAreaRef}
          value={input}
          onChange={handleInput}
          datatype="text"
          // onKeyUp={handleKeyUpTextArea}
          placeholder="Message"
          autoFocus={true}
          rows={1}
          className="block w-full py-2 pl-4 mx-3 bg-gray-100 rounded-lg outline-none focus:text-gray-700 h-auto max-h-60 resize-none row-auto"
          name="message"
          required
        />
        <button onClick={handleSend} type="submit">
          <svg
            className="w-5 h-5 text-gray-500 origin-center transform rotate-90"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

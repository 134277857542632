import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  ReactNode,
  createContext,
} from "react";
import { MAX_CONNECTIONS_LIMIT, MYCHATPWA_API_URL } from "../lib/constants";
import { useLibp2pContext } from "../context/ctx";

import { multiaddr, Multiaddr } from "@multiformats/multiaddr";
import { connectToMultiaddr } from "../lib/libp2p";
import devLog from "../Utils/Logger";

export interface PeerAddresses {
  multiaddrs: Multiaddr[];
  fetchPeers: () => Promise<void>;
}

const FetchPeersContext = createContext<PeerAddresses>({
  multiaddrs: [],
  fetchPeers: async () => {}, // Provide a default noop function
});

export const FetchPeersProvider = ({ children }: { children: ReactNode }) => {
  const { libp2p } = useLibp2pContext();

  // Add a state to store the timeout ID
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const fetchPeers = useCallback(async () => {
    try {
      const response = await fetch(MYCHATPWA_API_URL);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      devLog(`Only Peers: [${data.peers}] , length: <${data.peers.length}>`);

      if (!data.peers || data.peers.length < 1) {
        devLog(`GK no data.peers after api call`);
        return;
      }

      if (libp2p?.getConnections()?.length < MAX_CONNECTIONS_LIMIT) {
        for (const ma of data.peers) {
          // ...existing logic for connecting to peers...
          if (
            ma &&
            ma.toString().includes("webrtc") &&
            !ma.toString().includes(libp2p.peerId.toString()) &&
            !ma.toString().includes("webrtc/p2p")
          ) {
            // Split and insert 'p2p/' after 'webrtc/'
            const parts = ma.toString().split("/webrtc/");
            // return parts[0] + "/webrtc/p2p/" + parts[1];
            try {
              await connectToMultiaddr(libp2p)(
                multiaddr(parts[0] + "/webrtc/p2p/" + parts[1]),
              );
              continue;
            } catch (e) {
              devLog(
                `GK webrtc connectToMultiaddr e multi: ${
                  parts[0] + "/webrtc/p2p/" + parts[1]
                } error: ${e}`,
              );
            }
          }

          if (ma) {
            try {
              await connectToMultiaddr(libp2p)(multiaddr(ma));
            } catch (e) {
              devLog(`GK PWA connection ma: <${ma}> error: <${e}>`);
            }
          }
        }
      }
    } catch (error) {
      devLog("Error fetching peers:", error);
    }

    // Schedule the next call with a random interval
    const randomInterval = Math.random() * (300000 - 60000) + 60000; // Random between 1 and 5 minutes
    setTimeout(fetchPeers, randomInterval);
  }, []); // Add dependencies if necessary

  useEffect(() => {
    const timeoutId = setTimeout(fetchPeers, 1000); // Initial delay before the first fetch
    return () => clearTimeout(timeoutId); // Cleanup on unmount
  }, [[fetchPeers, timeoutId]]);

  return (
    <FetchPeersContext.Provider value={{ multiaddrs: [], fetchPeers }}>
      {children}
    </FetchPeersContext.Provider>
  );
};

export const useFetchPeers = () => useContext(FetchPeersContext);

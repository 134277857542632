import React, { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
// import Link from "next/link";
import { Link, useLocation } from "react-router-dom";
import QuestionIcon from "./UIElements/QuestionIcon";
import Modal from "./Modal";
import {
  EXPLANATION_INFO_1,
  EXPLANATION_INFO_2,
  EXPLANATION_INFO_3,
  HEADER_INFO_2,
  HEADER_INFO_3,
} from "../lib/constants";
// import Image from 'next/image'
// import { useRouter } from "next/router";

const navigationItems = [
  { name: "Connecting to a Peer", href: "/connections" },
  { name: "Chat", href: "/home" },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  // const router = useRouter();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Disclosure as="nav" className="border-b border-gray-200 bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="flex h-12 justify-between items-center">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      src="/decentr_logo.svg"
                      alt="chat logo"
                      height="36"
                      width="36"
                    />
                    <QuestionIcon setShowModal={setShowModal} />
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
                    {navigationItems.map((item) => (
                      <Link
                        key={item.href}
                        to={item.href}
                        className={classNames(
                          location.pathname === item.href
                            ? "border-indigo-500 text-gray-900"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
                        )}
                        aria-current={
                          location.pathname === item.href ? "page" : undefined
                        }
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/*<button*/}
                  {/*  type="button"*/}
                  {/*  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
                  {/*>*/}
                  {/*  <span className="sr-only">View notifications</span>*/}
                  {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                  {/*</button>*/}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      {/* <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        </Menu.Button> */}
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700",
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pt-2 pb-3">
                {navigationItems.map((item) => (
                  <Link key={item.href} to={item.href}>
                    <Disclosure.Button
                      key={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                          : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                      )}
                      aria-current={
                        location.pathname === item.href ? "page" : undefined
                      }
                    >
                      {item.name}
                    </Disclosure.Button>
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {showModal && (
        <Modal
          headerText={
            location.pathname === "/connections" ? HEADER_INFO_3 : HEADER_INFO_2
          }
          mainInfoText={
            location.pathname === "/connections"
              ? EXPLANATION_INFO_3
              : EXPLANATION_INFO_2
          }
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}

import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the shape of the context data
interface ILoginContext {
  loginName: string;
  setLoginName: React.Dispatch<React.SetStateAction<string>>;
}

// Create a new Context with an appropriate default value
export const LoginNameContext = createContext<ILoginContext | undefined>(
  undefined,
);

// Define the props for LoginNameProvider
interface LoginNameProviderProps {
  children: ReactNode;
}

export const LoginNameProvider: React.FC<LoginNameProviderProps> = ({
  children,
}) => {
  const [loginName, setLoginName] = useState<string>("anonymous"); // default value for loginName

  return (
    <LoginNameContext.Provider value={{ loginName, setLoginName }}>
      {children}
    </LoginNameContext.Provider>
  );
};

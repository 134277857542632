import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { openDB } from "idb";
import {
  INDEXED_DB_NAME,
  INDEXED_DB_STORE_NAME,
  INDEXED_DB_KEY_NAME,
  EXPLANATION_INFO_1,
  HEADER_INFO_1,
} from "../lib/constants";
import { LoginNameContext } from "../context/loginname-ctx";
import Modal from "./Modal";
import devLog from "../Utils/Logger";
import QuestionIcon from "./UIElements/QuestionIcon";

const LoginComponent = () => {
  const navigate = useNavigate();
  const loginCtx = useContext(LoginNameContext);

  const [showPopup, setShowPopup] = useState(false); // New state for popup visibility
  const [isNameValid, setIsNameValid] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    checkPreviousLogin();
  }, []);

  // Check if the context is provided
  if (!loginCtx) {
    // If not, you can render a fallback UI or handle the error as needed
    return <div>Error: LoginNameContext not found</div>;
  }

  const { loginName, setLoginName } = loginCtx;

  const checkPreviousLogin = async () => {
    const db = await openDB(INDEXED_DB_NAME, 1, {
      upgrade(db) {
        db.createObjectStore(INDEXED_DB_STORE_NAME);
      },
    });

    const name = await db.get(INDEXED_DB_STORE_NAME, INDEXED_DB_KEY_NAME);
    // const name = localStorage.getItem(INDEXED_DB_KEY_NAME); // Replace with the appropriate key

    if (!name || name === "" || name.length === 0) {
      setShowModal(true);
      return;
    }

    setLoginName(name);
    navigate("/home", { replace: true }); // Redirect to home if previously logged in
  };

  const handleInputChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    const specialCharRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;
    if (specialCharRegex.test(value)) {
      setIsNameValid(false);
    } else {
      setIsNameValid(true);
    }
    setLoginName(value);
  };

  const handleSubmit = async () => {
    devLog(`GK handleSubmit loginName <${loginName}> `);
    if (!loginName || loginName === "") {
      devLog(`GK handleSubmit loginName <${loginName}> #001 `);
      setShowPopup(true); // Show popup if input is empty
      setTimeout(() => setShowPopup(false), 3000); // Hide popup after 3 seconds
      return;
    }

    if (loginName && isNameValid) {
      devLog(`GK handleSubmit loginName <${loginName}> #002 `);
      try {
        devLog("GK handleSubmit loginName  #002.5", loginName);
        const db = await openDB(INDEXED_DB_NAME, 1);
        devLog("GK handleSubmit loginName  #002.5.1", loginName);
        await db.put(INDEXED_DB_STORE_NAME, loginName, INDEXED_DB_KEY_NAME);
        devLog("GK handleSubmit loginName  #002.5.1", loginName);
        navigate("/home"); // Redirect to home after successful login
      } catch (error) {
        devLog("GK handleSubmit Error in DB operations:", error);
      }

      // localStorage.setItem(INDEXED_DB_KEY_NAME, loginName); // Save to localStorage
    }
    devLog(`GK handleSubmit loginName <${loginName}> #003 `);
  };

  const handleClear = () => {
    setLoginName("");
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-center items-center h-screen">
        <div className="w-full max-w-xs">
          <div className="flex space-x-2">
            Decentralized chat app
            <QuestionIcon
              setShowModal={() => {
                setShowModal(true);
              }}
            />
          </div>
          <form
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="loginName"
              >
                Please enter your name
              </label>
              <div className="flex">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="loginName"
                  type="text"
                  placeholder="Login Name"
                  value={loginName}
                  onChange={handleInputChange}
                />
                {loginName && (
                  <button
                    type="button"
                    className="ml-2 px-4 py-2 bg-gray-200 text-gray-800 font-bold rounded hover:bg-gray-300"
                    onClick={handleClear}
                  >
                    Clear
                  </button>
                )}
              </div>
              {!isNameValid && (
                <p className="text-red-500 text-xs italic">
                  Invalid input. No special characters allowed.
                </p>
              )}
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleSubmit}
                disabled={!loginName || !isNameValid} // Disable button if input is empty or invalid
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <p>Please enter a name to continue.</p>
          </div>
        </div>
      )}
      {showModal && (
        <Modal
          headerText={HEADER_INFO_1}
          mainInfoText={EXPLANATION_INFO_1}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default LoginComponent;

import ChatContainer from "./Chat";
import Nav from "./Nav";
export default function ChatViewPage() {
  return (
    <div className="flex flex-col h-screen">
      <Nav />
      <main className="flex-1 overflow-auto">
        <ChatContainer />
      </main>
    </div>
  );
}
